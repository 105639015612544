<template>
  <div class="homepage pb-4 row">
    <div v-if="loadedQuestionnaires" class="col-12 mb-2">
      <h1 v-if="patient_issue && patient_issue.issue" class="display-2"
        v-t="'issues.' + patient_issue.issue.name + '.title'"></h1>
      <alert :type="alertType" v-if="patient_issue && patient_issue.issue">
        <i18n path="participant.home.status.description" class="lead">
          <template #issue> <span v-t="'issues.' + patient_issue.issue.name + '.title'"></span> </template>
          <template #status>
            <span v-text="$t('participant.home.status.' + patient_issue.valid)"></span>
          </template>
        </i18n>
      </alert>
    </div>
    <div v-if="loadedQuestionnaires" class="col-12 mb-4">
      <transition-group tag="div" name="card-fade"
        :class="['questionnaire-card-grid', { 'questionnaires-unstarted': questionnairesAreUnstarted }]" :css="false"
        @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
        <card :class="[questionnaireCardClass(pq)]" :key="pq.questionnaire.name" v-for="(pq, index) in questionnaires"
          :body="false" :data-index="index" :data-questionnaire="pq.questionnaire.name"
          @click.native="gotoQuestionnaire(pq)">
          <section class="card-body">
            <div class="q-name-bg">{{ $t('questionnaires.' + pq.questionnaire.name + '.title') }}</div>
            <h2 class="q-title">{{ $t('questionnaires.' + pq.questionnaire.name + '.title') }}</h2>

          </section>

          <footer class="card-footer d-flex">
            <div class="status-arrow ms-auto" v-if="pq == firstUnstartedOrInvalidPatientQuestionnaire">
              <span v-t="'participant.home.start_here'" v-if="questionnairesAreUnstarted"></span>
              <span v-t="'participant.home.continue'" v-else></span>
            </div>
            <router-link class="q-link-icon ms-auto stretched-link" :to="{ name: pq.questionnaire.name }">
              <fa v-if="pq.progressState == 'unstarted'" icon="play"
                :beat-fade="pq == firstUnstartedOrInvalidPatientQuestionnaire"
                :size="pq == firstUnstartedOrInvalidPatientQuestionnaire ? '3x' : 'lg'">
              </fa>
              <fa v-if="pq.progressState == 'started'" icon="times" size="3x" beat-fade style="" />
              <fa v-if="pq.progressState == 'valid'" icon="check" />
            </router-link>
          </footer>
        </card>
      </transition-group>
    </div>

    <div :class="['col-12 mb-2', questionnairesAreComplete ? 'order-1' : 'order-3']">
      <card head :body="false" :title="$t('participant.home.summary')"
        v-if="loadedQuestionnaires && scores && Object.keys(scores).length">
        <template v-if="loadedQuestionnaires">
          <scope :issue="patientIssueFromID(scoring.currentPatientIssueID, patient_issues)" v-slot="{ issue }">
            <div class="m-1">
              <div v-for="(score, key) in issue.scores" v-if="!['tsqm9', 'phq9'].includes(key)" :key="'column_' + key"
                class="g-1">
                <alert type="info" class="mb-1">

                  <h4 v-if="key != 'facit'" v-t="'questionnaires.' + key + '.title'"></h4>
                  <template v-if="key == 'pest'">
                    <div v-if="score <= 2">
                      <h5 v-t="'questionnaires.pest.feedback.score_lte_2.title'"></h5>
                      <p v-t="'questionnaires.pest.feedback.score_lte_2.message'"></p>
                    </div>
                    <div v-if="score >= 3">
                      <h5 v-t="'questionnaires.pest.feedback.score_3plus.title'"></h5>
                      <p v-t="'questionnaires.pest.feedback.score_3plus.message'"></p>
                    </div>
                  </template>
                  <template v-if="key == 'psaid'">
                    <p v-if="score.full < 2" v-t="'questionnaires.psaid.feedback.score_lt_2.message'"></p>
                    <p v-if="score.full >= 2 && score.full < 3"
                      v-t="'questionnaires.psaid.feedback.score_2_lt_3.message'">
                    </p>
                    <p v-if="score.full >= 3 && score.full < 5"
                      v-t="'questionnaires.psaid.feedback.score_3_lt_5.message'">
                    </p>
                    <p v-if="score.full >= 5" v-t="'questionnaires.psaid.feedback.score_5plus.message'"></p>
                  </template>
                  <template v-if="key == 'mdhaq'">
                    <h5 v-if="score >= 0 && score <= 0.5" v-t="'questionnaires.mdhaq.feedback.score_lte_0pt5.title'">
                    </h5>
                    <p v-if="score >= 0 && score <= 0.5" v-t="'questionnaires.mdhaq.feedback.score_lte_0pt5.message'">
                    </p>
                    <h5 v-if="score > 0.5 && score < 0.625"
                      v-t="'questionnaires.mdhaq.feedback.score_gt_0pt5-0pt625.title'">
                    </h5>
                    <p v-if="score > 0.5 && score < 0.625"
                      v-t="'questionnaires.mdhaq.feedback.score_gt_0pt5-0pt625.message'">
                    </p>
                    <h5 v-if="score > 0.625" v-t="'questionnaires.mdhaq.feedback.score_gt_0pt625.title'"></h5>
                    <p v-if="score > 0.625" v-t="'questionnaires.mdhaq.feedback.score_gt_0pt625.message'"></p>
                  </template>

                </alert>

              </div>
              <template v-if="patient_issue.other_fields.phq9_advice">

                <alert>
                  <h4 v-t="'questionnaires.phq9.title'"></h4>
                  <p v-html="$t('questionnaires.phq9.PHQ9_9.advice')"></p>
                </alert>
              </template>
            </div>

            <div class="card-header">
              <row class="align-items-center">
                <column sizes="auto">
                  <btn outline @click.native="scoring.currentPatientIssueID = prevPatientIssueId(patient_issues)"
                    :disabled="!prevPatientIssueId(patient_issues)">
                    <fa icon="chevron-left" />
                  </btn>
                </column>
                <column>
                  <!-- <i18n path="admin.patients.issue_overview.title" class="mb-0 flex-grow-1" tag="h6">
                    <template #code><span class="patient-code ms-3"> {{ patient.code }}</span></template>
                    <template #issue><span class="ms-3">{{ $t('issues.' + issue.issue.name + '.title')
                        }}</span></template>
                    <template #due_at><span class="text-sm">{{ $d(new Date(issue.due_at), 'numeric', 'en-GB')
                        }}</span></template>
                  </i18n> -->
                </column>
                <column sizes="auto">
                  <btn outline @click.native="scoring.currentPatientIssueID = nextPatientIssueId(patient_issues)"
                    :disabled="!nextPatientIssueId(patient_issues)">
                    <fa icon="chevron-right" />
                  </btn>

                </column>
              </row>
            </div>
            <row class="g-1 m-1">
              <column v-for="(score, key) in issue.scores" v-if="key != 'tsqm9'" :key="'column_' + key"
                :sizes="{ sm: 6, md: 4, lg: 3 }" class="g-1">
                <router-link :to="{ name: key }" class="text-decoration-none">
                  <card class="h-100 text-center" :body="false">
                    <div class="card-body d-flex flex-column">
                      <h5 v-text="$t('questionnaires.' + key + '.title')" class="flex-grow-1 mb-4"></h5>
                      <score-speedo v-bind="scoreSpeedoProps(score, key)" class="mt-auto"></score-speedo>
                    </div>

                  </card>
                </router-link>
              </column>
              <column v-if="issue.scores.tsqm9" :sizes="{ sm: 12, md: 9, lg: 9 }">
                <router-link :to="{ name: 'tsqm9' }" class="text-decoration-none">
                  <card :title="$t('questionnaires.tsqm9.title')" class="text-center">
                    <row>
                      <column :sizes="{ sm: 4 }">
                        <div class="card-body d-flex flex-column">
                          <h5 class="text-center" v-t="'questionnaires.tsqm9.effectiveness'"></h5>
                          <score-speedo
                            v-bind="scoreSpeedoProps(Math.round(issue.scores.tsqm9.effectiveness), 'tsqm9')"></score-speedo>
                        </div>
                      </column>
                      <column :sizes="{ sm: 4 }">
                        <div class="card-body d-flex flex-column">
                          <h5 class="text-center" v-t="'questionnaires.tsqm9.convenience'"></h5>
                          <score-speedo
                            v-bind="scoreSpeedoProps(Math.round(issue.scores.tsqm9.convenience), 'tsqm9')"></score-speedo>
                        </div>
                      </column>
                      <column :sizes="{ sm: 4 }">
                        <div class="card-body d-flex flex-column">
                          <h5 class="text-center" v-t="'questionnaires.tsqm9.satisfaction'"></h5>
                          <score-speedo
                            v-bind="scoreSpeedoProps(Math.round(issue.scores.tsqm9.globalSatisfaction), 'tsqm9')"></score-speedo>
                        </div>
                      </column>
                    </row>
                  </card>
                </router-link>
              </column>
            </row>
          </scope>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import patientIssueMixin from '~/mixins/patient_issue'
import { gsap } from 'gsap'
export default {
  middleware: ['auth', 'role:Participant', 'check-registered'],
  mixins: [patientIssueMixin],
  metaInfo() {
    return { title: this.$t('participant.home.title') }
  },
  data() {
    return {
      loadingQuestionnaires: false,
      loadedQuestionnaires: false,
      focussedQuestionnaire: null,
      scoring: {
        currentPatientIssueID: null,
      },
    }
  },
  mounted() {
    var params = { ...this.$route.params }
    if (!params.issue) params.issue = 'current'
    params.include = ['questionnaires', 'scores']
    this.loadingQuestionnaires = true
    this.getPatientIssue({ params })
      .then(() => {
        this.getPatientIssues({ params: { include: ['scores'] } })
          .then(() => {
            // if (this.$route.params.questionnaire) this.setFocussedQuestionnaire(this.$route.params.questionnaire)
            this.loadingQuestionnaires = false
            this.loadedQuestionnaires = true
            if (!this.scoring.currentPatientIssueID && this.latestIssue) {
              this.scoring.currentPatientIssueID = this.latestIssue.id
            }
          });
      });
  },
  computed: {
    ...mapState('resources/patient', ['patient', 'patient_issue', 'patient_issues']),
    latestIssue() {
      if (this.patient_issues)
        return this.patient_issues[this.patient_issues.length - 1]
      else return null
    },
    alertType() {
      if (this.patient_issue && this.patient_issue.valid == 'valid') return 'success'
      return 'info'
    },
    gridEl() {
      return this.$el.querySelector('.questionnaire-card-grid')
    },
    scores() {
      if (this.patient_issue && this.patient_issue.scores)
        return this.patient_issue.scores
      return {}

    },
    questionnaires() {
      if (this.patient_issue && this.patient_issue.patient_questionnaire)
        return this.patientQuestionnaires(this.patient_issue)
      return []
    },
    questionnairesAreUnstarted() {
      var unstarted = true
      for (var pq of this.questionnaires) {
        if (['invalid', 'valid'].includes(pq.progressState)) unstarted = false
      }
      return unstarted
    },
    firstUnstartedOrInvalidPatientQuestionnaire() {
      for (var pq of this.questionnaires) {
        if (pq.progressState == 'unstarted' || pq.progressState == 'started') return pq
      }
      return null
    },
    questionnairesAreComplete() {
      var complete = true
      for (var pq of this.questionnaires) {
        if (pq.progressState != 'valid') complete = false
      }
      return complete
    },

  },
  methods: {
    ...mapActions('resources/patient', ['getPatientIssue', 'getPatientIssues']),
    progressIcon(pq) {
      var icon = 'play'
      if (pq.progressState == 'valid') icon = "check"
      if (pq.progressState == 'started') icon = "times"
      return icon
    },
    questionnaireCardClass(pq) {
      var progressState = (pq) ? 'questionnaire-card-' + pq.progressState : ''
      var isFirstUnstarted = (pq == this.firstUnstartedOrInvalidPatientQuestionnaire) ? 'first-unstarted' : ''
      return ['questionnaire-card', progressState, isFirstUnstarted]
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        scale: 1,
        delay: el.dataset.index * 0.15,
        onComplete: done
      }, 0.5)
    },
    onLeave(el, done) {
      console.log('leaving')
      gsap.to(el, {
        opacity: 0,
        scale: 0.9,
        delay: el.dataset.index * 0.15,
        onComplete: done
      })
    },
    onBeforeEnter(el) {
      el.style.opacity = 0
      el.style.transform = 'scale(1.1)'
    },
    gotoQuestionnaire(pq) {
      this.$router.push({ name: pq.questionnaire.name })
    },
  },

  watch: {
    '$route.params.questionnaire'(newVal) {
      // this.setFocussedQuestionnaire(newVal)
    }
  }
}

</script>
<style></style>
<template>
  <questionnaire :title="$t('questionnaires.psaid.title')">
    <alert type="info" class="fs-5" v-t="'questionnaires.psaid.introduction'"></alert>
    <row>
      <column>
        <template v-for="diName in psaidItems">
          <h4 class="mt-4" :key="'label' + diName">{{ dataitems[diName].trans("title") }}</h4>
          <form-group :key="'form-group' + diName" :error="errors[diName]" :di="dataitems[diName]" v-slot="{ di }"
            class="mb-4">
            <btn-group outline :readonly="isSubmitted" :di="di" v-model="records[di.name]" justified
              :size="$mq | mq({ xs: 'sm', sm: 'sm', md: '', lg: 'lg' })"></btn-group>
            <div class="d-flex">
              <label class="me-auto">{{ dataitems[diName].trans("left") }}</label>
              <label class="ms-auto">{{ dataitems[diName].trans("right") }}</label>
            </div>
          </form-group>
        </template>
      </column>
    </row>
    <card class="h-100" v-if="isSubmitted">
      <row>
        <column :sizes="{ sm: 6, lg: 4 }" :offset="{ sm: 3, lg: 0 }">
          <h4 class="display-4 text-center">{{ $t('questionnaires.psaid.score') }}</h4>
          <score-speedo :score="{ score, min: 0, max: 10 }" :domain="[0, 3, 10]"
            :colours="['#198754', '#ffc107', '#dc3545']" :ticks="40"></score-speedo>
        </column>
        <column :sizes="{ lg: 8 }">
          <alert type="info" v-if="allScored">
            <p v-if="score < 2" v-t="'questionnaires.psaid.feedback.score_lt_2.message'"></p>
            <p v-if="score >= 2 && score < 3" v-t="'questionnaires.psaid.feedback.score_2_lt_3.message'"></p>
            <p v-if="score >= 3 && score < 5" v-t="'questionnaires.psaid.feedback.score_3_lt_5.message'"></p>
            <p v-if="score >= 5" v-t="'questionnaires.psaid.feedback.score_5plus.message'"></p>
          </alert>
        </column>
      </row>
    </card>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
import { mapState } from 'vuex'
export default {
  name: 'psaid',
  mixins: [questionnaire],
  computed: {
    ...mapState('resources/patient', ['patient_issue']),
    psaidItems() {
      return [
        'PsAID1', 'PsAID2', 'PsAID3', 'PsAID4', 'PsAID5', 'PsAID6', 'PsAID7', 'PsAID8', 'PsAID9', 'PsAID10', 'PsAID11', 'PsAID12'
      ]
    },
    score() {
      var scores = []
      var missing = 0
      this.psaidItems.forEach((diName) => {
        if (this.records[diName] === null) missing++;
        else scores.push(this.records[diName] * this.dataitems[diName].json.score_multiplier)
      })

      var score = scores.reduce((a, b) => a + b, 0);
      var avg = (score / scores.length) || 0;
      /*
      Missing data imputation
      If one of the 12 NRS values composing the PsAID is missing, the imputation is as follows:
      calculate the mean value of the 11 other (non-missing) NRS (range, 0-10)
      impute this value for the missing NRS
      Then, calculate the PsAID as explained above.
      If 2 or more of the NRS are missing, the PsAID is considered as missing value (no imputation).
      */
      if (missing > 1) return false
      if (missing == 1 && scores.length == 11) {
        scores.push(avg)
        score = scores.reduce((a, b) => a + b, 0);
      }
      /*
      The total is divided by 20.
      Thus, the range of the final PsAID value is 0-10 where higher figures indicate worse status.
      */
      return parseFloat(score / 20).toFixed(3)
    },
    allScored() {
      var scored = true
      var missing = 0
      this.psaidItems.forEach((diName) => {
        if (this.records[diName] === null) missing++;
      })
      if (missing > 1) scored = false
      return scored
    },
    depressionScore5Plus() {
      return (this.records.PsAID12 !== null && this.records.PsAID12 >= 5);
    },
    isSubmitted() {
      if (this.patient_issue && this.patient_issue.patient_questionnaire)
        return this.patient_issue.patient_questionnaire.find((pq) => pq.questionnaire_id == this.questionnaire.id).valid == 1
    }
  }
}

</script>

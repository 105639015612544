<template>
  <questionnaire :title="$t(lp + '.title')" @saving="savingHook" @saved="saved" :disabledSave="saveable">
    <!-- <test-content>
      <div v-for="section in dataitems.listSections()">
        <a @click="debugChange(section)" href="#">{{ section }}</a>
      </div>
    </test-content> -->
    <alert type="info" v-if="canUpgrade && selected.index != -1">
      <i18n path="questionnaires.consent.consent_upgrade_info" tag="div" class="d-flex">
        <template #upgrade_consent>
          <span v-t="'questionnaires.consent.upgrade_consent'" />
        </template>
        <template #btn>
          <btn outline @click.native="startUpgrade" class="ms-auto">
            <span v-t="'questionnaires.consent.upgrade_consent'" />
          </btn>
        </template>
      </i18n>
    </alert>

    <row v-if="!routeChanging">
      <column v-if="isConsented && selected.index != -1" :sizes="{ md: 3 }">
        <consent-list :selected="selected" :consent="component" :records="records">
        </consent-list>
      </column>
      <column :sizes="{ md: isFirstConsent || isConsented && selected.index == -1 ? 12 : 9 }">
        <consent-form :consent="component" :mode="mode" :di="selectedSection" :disabled="selectedRecordInactive"
          :dataitems="selectedDataitems" :record="editableRecord" :errors="errors" :validation="validation">
        </consent-form>
      </column>
    </row>
    <div v-if="savedInvalidConsent">
      <alert type="danger" class="mx-2 mb-4 mx-md-5 px-md-5">
        <i18n path="questionnaires.consent.invalid_consent_warning">
          <template #study_team>
            <a class="fw-bold" :href="'mailto:' + $t('questionnaires.consent.study_team_email')" target="_blank"
              v-t="'questionnaires.consent.study_team_label'" />
          </template>
        </i18n>
      </alert>
      <hr>
      <form-group :di="dataitems.invalid_consent_reason" class="mx-4 mb-4 mx-md-5">
        <textarea class="form-control" :di="dataitems.invalid_consent_reason"
          v-model="records.invalid_consent_reason" />
      </form-group>
    </div>
    <template #next-btn>
      <btn outline v-if="completedForm && isFirstConsent" @click.native="updatePatientAndProgress"
        v-t="'questionnaires.questionnaire.next'"></btn>
      <span></span>
    </template>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js";
import consentForm from '~/pages/questionnaires/consent/consent-form.vue'
import consentList from '~/pages/questionnaires/consent/consent-list.vue'
import { head, tail, map, orderBy } from "lodash";
import { mapActions, mapGetters } from 'vuex'
import { getNextKeyInSequence } from '~/router/sequences.js'
export default {
  name: 'consent',
  middleware: ['check-registration', 'auth'],
  mixins: [questionnaire],
  components: { consentForm, consentList },
  // what does active mean>
  // currently selected? -- this is selected
  // currently in use? -- this is active
  data() {
    return {
      showHistory: true,
      selected: {
        sectionName: null,
        index: null
      },
      selectedSectionOveride: null,
      validate: false,
      clears: {},
      editableRecord: null,
      component: this
    }
  },
  mounted() {
    this.$watch("mode", function (newVal, oldVal) {
      if (oldVal == "first_consent" && newVal == "consented") { // handing for first consent
        if (this.isParticipant) {
          this.$store.dispatch('auth/fetchUser')
          this.$router.push({ name: 'registration_check' }).catch(() => { });
        }
      }
    }.bind(this));
    this.$watch("selected", function (newVal, oldVal) {
      if (this.selectedRecord) {
        this.editableRecord = this.selectedRecord.copy();
        this.editableRecord.id = null
      }
      else if (this.isUnconsentedButHasRecords) {
        this.editableRecord = this.selectedSectionRecord[0]
      }
      else {
        this.editableRecord = this.selectedSection.createSection(this.records)
      }
    }.bind(this), { deep: true, immediate: true });
  },

  computed: {
    ...mapGetters("auth", ["user", "isParticipant", "isTeam"]),
    selectedSectionName() {
      if (this.selectedSectionOveride) {
        return this.selectedSectionOveride
      }
      if (this.selected.sectionName) {
        return this.selected.sectionName;
      }
      else if (this.isFirstConsent) {
        return this.questionnaire.extraData.activeConsent;
      } else {
        return this.records.activeForms[0].sectionDataitem.name;
      }
    },
    selectedIndex() {
      if (this.selected.index == null) {
        return this.records.activeForms[0].id
      }
      return this.selected.index;
    },
    selectedSection() {
      return this.dataitems[this.selectedSectionName]
    },
    selectedSectionRecord() {
      return this.records[this.selectedSectionName];
    },
    selectedRecord() {
      if (this.mode != "first_consent") {
        return this.selectedSectionRecord.find(function (record) {
          return record.id == this.selectedIndex;
        }.bind(this));
      }
    },
    selectedDataitems() {
      return this.dataitems.fromSection(this.selectedSectionName);
    },
    selectedRecordInactive() {
      var activeRecord = this.records.activeForms[0];
      return !this.isFirstConsent && this.selectedRecord && activeRecord && this.selectedRecord.id != activeRecord.id;
    },
    isUnconsentedButHasRecords() {
      return this.isFirstConsent && this.selectedSectionRecord.length>0;
    },
    isConsented() {
      return this.mode == "consented";
    },
    isReconsenting() {
      return this.mode == "reconsenting";
    },
    isFirstConsent() {
      return this.mode == "first_consent";
    },
    mode() {
      return this.questionnaire?.extraData.consentMode;
    },
    breadcrumbs() {
      if (this.isParticipant) {
        return [{ title: 'Home', to: { 'name': 'home' } }, { title: this.questionnaire.lang['title'] }]
      }
    },
    completedForm() {
      return this.questionnaire?.records?.valid == 1 &&
        Object.keys(this.errors).length == 0
    },
    saveable() {
      return this.editableRecord && !this.editableRecord.isDirty(); // the record have been changed.
    },
    savedInvalidConsent() { return this.questionnaire?.records?.valid == 0 && !this.consented && this.editableRecord && !this.editableRecord.isValid },
    canUpgrade() {
      return this.records?.activeForms[0] && this.records?.activeForms[0]?.sectionDataitem.name != this.questionnaire?.extraData.activeConsent;
    },

  },
  methods: {
    ...mapActions('auth', ['updateUsersPatient']),
    debugChange(sectionName) {
      this.selectedSectionOveride = sectionName;
      this.selected = { sectionName, index: 0 };
    },
    startUpgrade() {
      var sectionDataitem = this.dataitems[this.questionnaire.extraData.activeConsent];
      this.setNewSelectedForm(sectionDataitem);
    },
    setNewSelectedForm(sectionDataitem) {
      this.selected.sectionName = sectionDataitem.name;
      this.selected.index = -1
    },
    setToMostRecent() {
      this.selected.sectionName = this.selectedSection.name;
      this.selected.index = this.records.activeForms[0].id;
    },
    savingHook() {
      var record = this.editableRecord;
      record.validate();
      record.validationWatch(this, "editableRecord");
      if (record.isValid || (this.isFirstConsent && !this.isUnconsentedButHasRecords)) {
        this.selectedSectionRecord.push(record)
      } else if (this.isUnconsentedButHasRecords) {
        return true;
      }
      else {
        return false;
      }
    },
    saved() {
      this.setToMostRecent();
      if (this.questionnaire && this.questionnaire.wasValidated && this.questionnaire.records.valid == 1) {
        this.updatePatientAndProgress()
      }
    },
    updatePatientAndProgress() {
      if (this.user.patient) {
        var in_sequence = this.user.patient.in_sequence
        var isConsented = this.$store.getters['auth/isConsented']
        if (this.user.patient.status == 'consent') {

          var status = getNextKeyInSequence(in_sequence, 'consent')
          this.updateUsersPatient({ id: this.user.patient.id, status, in_sequence })
            .then(() => {

              this.$router.push({ name: 'confirmation' });
              this.$gtag.event({
                event_category: 'auth',
                event_action: 'consent',
                value: 'User consented',
                user_id: this.user.id
              })
            })
        }


      }
    }
  },
}
</script>
